<template>
  <div style="position: relative;">
    <container>
      <middle-title style="margin-bottom: 40px" title="公司动态" :description="description" />
      <a-row type="flex" :gutter="[32, 32]">
        <a-col v-for="(blog, index) in blogs" :xxl="8" :xl="8" :lg="12" :md="24" :sm="24" :key="index">
          <div style="position: relative">
            <div class="post-date bg-primary">
              <div class="date">{{ blog.day }}</div>
              <div class="month">{{ blog.month }}</div>
              <div class="year">{{ blog.year }}</div>
            </div>
            <div class="img-effect">
              <img :src="blog.img" style="object-fit: cover;" />
            </div>
            <div class="blog-info">
              <h5 class="title"><a>{{ blog.title }}</a></h5>
              <p style="margin-top: 10px" class="description">
                {{ blog.description }}
              </p>
              <a style="font-weight: bold" :href="blog.url" target="blank">
                阅读全文
                <DoubleRightOutlined />
              </a>
              <div class="post-tags clearfix">
                <div class="tag" v-for="(item, i) in blog.tag" :key="i">{{ item }}</div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </container>
  </div>

</template>

<script>
import { DoubleRightOutlined } from '@ant-design/icons-vue'
import MiddleTitle from '@/components/common/MiddleTitle'
export default {
  name: 'BlogGrid',
  components: { MiddleTitle, DoubleRightOutlined },
  data() {
    return {
      img: require('@/assets/image/article/anniversary.jpg'),
      description: '辰宇矢志成为行业领先的环球财务规划服务机构，帮助客户实现不同阶段的财智人生，引领环球财务规划行业快速发展！',
      blogs: [
        {
          year: '2024',
          month: 'Jul',
          day: '22',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image1.jpg',
          title: '国际游学：辰翰传承商学与日本的六日奇遇',
          description: '辰翰传承商学的国际游学之旅首站设在日本，由辰宇集团创始人曾宪奎、辰翰商学院院长孙一晴以及名誉院长陈少文老师带队，一行20名来自各地的学员共同参与并圆满完成了这次日本之旅。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247557810&idx=1&sn=ee86152f0e6f41a0d6df4940b16bcba6&chksm=fdb775c2cac0fcd4a9c0b1ffa0f496504f37da1e2e128dd65fb352f78016098557532caedc87&token=991621836&lang=zh_CN#rd',
          tag: ['辰翰传承商学', '国际游学', '日本站']
        },
        {
          year: '2024',
          month: 'Jul',
          day: '8',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image2.jpg',
          title: '寻味山水间 画遇千岛湖 | 辰宇2024年度管理层会议圆满结束',
          description: '辰宇集团的高层管理人员在湖光山色的千岛湖畔举行了年度会议。会议期间，与会者进行了深入的思想交流，讨论了行业趋势，并规划了公司未来的发展方向，为集团的发展做出了重要贡献。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247557187&idx=1&sn=dfee5d73f0939e0edf7ae5cada3a3104&chksm=fdb77733cac0fe25a8d3fa8c1f17ea01db5d9c41d39b1beea7f72ad773794ed18441e62abde0&token=991621836&lang=zh_CN#rd',
          tag: ['辰宇集团', '管理层会议', '杭州千岛湖']
        },
        {
          year: '2024',
          month: 'Jul',
          day: '5',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image5.jpg',
          title: '势起争流 | 辰宇2024年第二季度会议暨八周年庆典圆满落幕',
          description: '在这个别样又热烈的七月，深圳市前海辰宇投资咨询有限公司第二季度会议暨八周年庆典活动于2024年7月5日在深圳华侨城洲际大酒店隆重举行。来自全国各地的辰宇人汇聚一堂，共同回顾了这个季度的工作成果，一起庆祝了辰宇八周年的生日。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247556892&idx=1&sn=700cae2cc3ad440e4b9e9435caa50ee6&chksm=fdb6886ccac1017aa9279713fdc725f2185ba9225e612c30ea908a9998d2062440eb0933b87e&scene=178&cur_album_id=2915260482901753857#rd',
          tag: ['辰宇集团', '八周年庆典']
        },
        {
          year: '2024',
          month: 'Jun',
          day: '1',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image3.jpg',
          title: '辰宇公益四年，照亮孩子们的追梦之路！',
          description: '正值加油未来项目迎来十周年庆典，辰宇集团深感荣幸被邀请参加这一盛大的活动，并荣获了“善行伙伴”的称号。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247555164&idx=1&sn=1232f1c3e1c90c17561a1879f912f81c&chksm=fdb68f2ccac1063ab4d06f69af05f1456b92ae04d722528d32fb222713651c1e83c26cdb41be&scene=178&cur_album_id=2915260482901753857#rd',
          tag: ['辰宇公益', '善行伙伴', '月捐计划']
        },
        {
          year: '2024',
          month: 'May',
          day: '25',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image6.jpg',
          title: '喜讯 | 辰宇集团斩获「2024亚洲品牌创新500强」与「亚洲产业(行业)影响力品牌」两项殊荣',
          description: '辰宇集团凭借其在行业内的卓越表现与广泛影响力，成功斩获「2024亚洲品牌创新500强」与「亚洲产业(行业)影响力品牌」两项殊荣！',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247554901&idx=1&sn=f41db32dc4fe87d87591e2e4eed9f4bf&chksm=fdb68025cac10933a40ef8b21fb87d3bc897c0234b6eda536da74c03b9f4fa8c73a04b7d7208&scene=178&cur_album_id=2915260482901753857#rd',
          tag: ['辰宇集团', '荣誉奖项']
        },
        {
          year: '2024',
          month: 'Apr',
          day: '9',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image4.jpg',
          title: '双牙之行 | 世界那么大，我想「宇」你踏上旅途',
          description: '今年我们启程前往西班牙与葡萄牙，踏上这片洋溢着热情与浪漫的大地，在这次旅行中，我们不仅欣赏到了世界各地的美景，更品尝了独具特色的美食。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247554157&idx=1&sn=aed7e16a8a360ed8e394f4525a362f60&chksm=fdb6831dcac10a0bec4d02217188a4b37152324634c6f974728b26f62b88b4c3d2076897e608&scene=178&cur_album_id=2915260482901753857#rd',
          tag: ['辰宇集团', 'SUNIVERS  CONVENTION', '西班牙', '葡萄牙']
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.post-date {
  z-index: 10;
  color: #fff;
  position: absolute;
  left: 20px;
  top: 0;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;

  & .date {
    font-weight: 600;
    font-size: 15px;
    padding: 10px 5px 5px;
    position: relative;
  }

  & .month {
    position: relative;
    font-size: 12px;

    &:after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 50%;
      height: 1px;
      background-color: #fff;
      margin: 0 -10px;
      width: 20px;
    }
  }

  & .year {
    margin-top: 8px;
  }

}

.img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle;

  img {
    width: 100%;
    height: 170px;
  }
}

.blog-info {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ebebeb;

  & .title {
    margin-bottom: 10px;
  }

  & .post-tags {
    margin-top: 10px;
    border-top: 1px solid #e9e9e9;
    padding-top: 10px;

    & .tag {
      font-size: 13px;
      border: 1px solid #ebebeb;
      padding: 2px 8px 1px;
      color: #777;
      margin: 3px;
      display: inline-block;
    }
  }
}
</style>
